import React, { Suspense, useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { ZapparCamera, ImageTracker, InstantTracker, ZapparCanvas, Loader, BrowserCompatibility } from '@zappar/zappar-react-three-fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import glb from './assets/waving.glb';
import targetImage from './assets/example-tracking-image.zpt';
import { Stats } from '@react-three/drei';

let action;

const Model = (props) => {
	const clock = new THREE.Clock();
	const gltf = useLoader(GLTFLoader, glb);
	const mixer = new THREE.AnimationMixer(gltf.scene);

	action = mixer.clipAction(gltf.animations[0]);
	gltf.scene.rotateX(Math.PI / 2);

	useFrame(() => mixer.update(clock.getDelta()));

	return <primitive object={gltf.scene} {...props} />;
};

function App() {
	const [placementMode, setPlacement] = useState(true);
	return (
		<>
			<BrowserCompatibility />
			<ZapparCanvas>
				<ZapparCamera />
				<Stats className="stats" />
				<Suspense fallback={null}>
					<Suspense fallback={null}>
						<InstantTracker placementMode={placementMode} placementCameraOffset={[0, -1, -6]}>
							<gridHelper args={[3, 10, 0xffffff, 0xffffff]} visible={placementMode}></gridHelper>
							<Model rotation={[90, 0, 0]} />
						</InstantTracker>
					</Suspense>
				</Suspense>
				<directionalLight position={[2.5, 8, 5]} intensity={1.5} />
				<Loader />
			</ZapparCanvas>
			<div
				id="zappar-button"
				role="button"
				onKeyPress={() => action.play()}
				tabIndex={0}
				onClick={() => {
					setPlacement((p) => !p);
					action.play();
				}}
			>
				{placementMode ? 'Place Model' : ' Pick up Model'}
			</div>
		</>
	);
}

export default App;
